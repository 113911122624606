<template>
  <div class="container-fluid mt-5">
    <div class="row">
      <div class="col-xl-12 order-xl-1">
        <card>
          <div slot="header" class="row align-items-center">
            <div class="col-8">
              <h3 class="mb-0">{{ $t("USERS.EDIT_USER") }}</h3>
            </div>
            <div class="col-4 text-right">
              <base-button
                @click="goBack"
                type="button"
                class="btn btn-sm btn-primary"
              >
                {{ $t("COMMON.RETURN_TO_LIST") }}
              </base-button>
            </div>
          </div>
          <div class="card-body">
            <user-form
              :loading="loading"
              :userData="user"
              :formErrors="formErrors"
              @userSubmitted="handleSubmit"
              @formChanged="() => (alertLeave = true)"
            />
          </div>
        </card>
      </div>
    </div>
  </div>
</template>

<script>
import { cloneDeep } from "lodash";
import alertLeave from "@/mixins/alert-leave-mixin";
import UserForm from "./partials/UserForm.vue";
import defaultUser from "./defaultUser";

export default {
  layout: "DashboardLayout",

  components: { UserForm },

  mixins: [alertLeave],

  data() {
    return {
      user: cloneDeep(defaultUser),
      formErrors: null,
      loading: false,
    };
  },

  created() {
    this.get();
  },

  methods: {
    async get() {
      this.loading = true;
      try {
        const id = this.$route.params.id;
        await this.$store.dispatch("users/get", id);
        this.user = { ...this.user, ...this.$store.getters["users/user"] };
        this.user.is_staff = !!this.user.is_staff;
        this.user.restrict_to_locations = !!this.user.restrict_to_locations;
        this.loading = false;
      } catch (error) {
        this.$notify({
          type: "danger",
          message: this.$t("ERRORS.SOMETHING_WENT_WRONG"),
        });
        this.loading = false;
      }
    },

    goBack() {
      this.$router.push({ name: "List Users" });
    },

    viewUser() {
      this.alertLeave = false;
      this.$router.push({ name: "View User", params: { id: this.user.id } });
    },

    async handleSubmit(user) {
      this.loading = true;

      const userData = cloneDeep(user);
      if (userData.roles[0]) {
        if (!userData.roles[0].id) {
          delete userData.roles[0].id;
        }
      }
      if (userData.organization) {
        if (!userData.organization.id) {
          delete userData.organization;
        }
      }
      if (userData.reseller) {
        if (!userData.reseller.id) {
          delete userData.reseller;
        }
      }
      if (!userData.password) {
        delete userData.password;
        delete userData.password_confirmation;
      }

      try {
        await this.$store.dispatch("users/update", userData);
        this.$notify({
          type: "success",
          message: this.$t("USERS.USER_UPDATED"),
        });
        this.viewUser();
        this.loading = false;
      } catch (error) {
        this.$notify({
          type: "danger",
          message: this.$t("ERRORS.SOMETHING_WENT_WRONG"),
        });
        this.formErrors = error.response.data.errors;
        this.loading = false;
      }
    },
  },
};
</script>
